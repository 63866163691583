// Front Page Start ------------------------------------------------------------
.start {
  height: 70vh;
  .logo-animation-start {
  	position: absolute;
  	width: 450px;
  	height: 420px;
  	margin-left: -225px;
  	margin-top: -210px;

  	.logo {
  		.pinheiro {
  			width: 500px;
  			height: 100%; } } } }
.slogan {
  height: 25vh;
  .wraper-slogan {
    max-width: 1000px;
    .views-field-body {
    	p {
    		font-size: 1.5em; } } } }

// End Front Page --------------------------------------------------------------

.flex-direction-nav, .slick__arrow {
  a.flex-next, a.flex-prev, i.slick-next, i.slick-prev {
    display: block !important; }

  a.flex-next, i.slick-next {
    right: -150px !important; }

  a.flex-prev, i.slick-prev {
    left: -150px !important; } }


h2 {
	font-size: 2.8em !important; }

//Topbar and menu
#menu-wrapper {
	.menu-links {
		position: absolute;
		width: 100%;
		bottom: 30px;
		#block-system-main-menu {
			.menu {
				li {
					a {
						font-size: 5em; } } } } } }


#block-views-home-page-produtos {
  h2 {
    max-width: 500px; }

  .view-content {
    .views-row {
      &::after {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 50%;
        height: 100%;
        width: 6px;
        background-color: $primary-color;
        margin-left: -3px; } }
    .views-row-odd {
      text-align: right;
      .align {
        float: left;
        padding: 0 120px;
        position: relative;
        &::after {
          content: "";
          position: absolute;
          top: 75px;
          right: 0;
          width: 100px;
          height: 6px;
          background-color: $primary-color; } }
      .views-field-view-node {
        text-align: right;
        position: relative;
        display: inline-block; } }
    .views-row-even {
      text-align: left;
      .align {
        position: relative;
        padding: 0 120px;
        float: right;
        &::before {
          content: "";
          position: absolute;
          top: 75px;
          left: 0;
          width: 100px;
          height: 6px;
          background-color: $primary-color; } } } } }

#node-2 {
	.secondsection-node {
		text-align: left; } }

.block-webform {
	h2 {
		font-size: 2.5rem; } }

.page-node-1, .node-contacto {
  .wrap-contact {
    .view-display-id-about {
      max-width: 1000px; } } }

.node-produto {
  .services {
    .slick {
      .slick-prev {
        display: block !important; }
      .slick-next {
        display: block !important; } } } }
