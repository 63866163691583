.slick {
  padding: 0em 0em;
  .slide {
    width: 100%;
    padding: 0 0; } }
.flex-direction-nav, .slick__arrow {
  a.flex-next, a.flex-prev, i.slick-next, i.slick-prev {
    display: none !important; } }

body,article {
  background-color: $white;
  overflow-x: hidden;

  #document {
    background-color: $white; } }


// Idioma

#topbar {
  .idioma {
    display: block;
    position: absolute;
    top: 25px;
    left: auto;
    right: 23px;
    bottom: auto;
    opacity: 0;
    transition: opacity 200ms linear 700ms;
    .language-switcher-locale-url {
      margin: 0px;
      padding: 0px;
      li {
        width: 36px;
        height: 36px;
        text-align: center;
        line-height: 32px;
        margin-right: 0.4em;
        border-radius: 50%;
        display: inline-block;
        background-color: $secondary-color;
        a {
          color: $primary-color;
          text-decoration: none;
          transition: color 300ms ease;
          &:hover {
            background-color: transparent; } } }
      li.active {
        background-color: $primary-color;
        a {
          color: white; } }
      li:hover {
        background-color: darken($primary-color, 25);
        a {
          color: white; } } } } }
.topbar-open {
  .idioma {
    transition: opacity 100ms linear;
    opacity: 1 !important;
    pointer-events: auto; } }


// Front Page Start ------------------------------------------------------------
.start {
  display: block;
  width: 100%;
  height: 400px;
  background-color: $white;

  .logo-animation-start {
    position: absolute;
    width: 240px;
    height: 270px;
    top: 50%;
    margin-left: -120px;
    margin-top: -100px;
    left: 50%;
    .logo {
      position: relative;
      width: 100%;
      text-align: center;
      .mancha {
        opacity: 0; }
      .pinheiro {
        position: absolute;
        width: 300px;
        height: 100%;
        left: 0;
        top: 1px;
        transform:  translateY(10%) scale(0.9);
        opacity: 0; } }
    .letering {
      position: absolute;
      left: 0;
      bottom: 0;
      opacity: 0;
      display: block; } }

  .show {
    .logo {
      .mancha {
        transition: opacity 1500ms ease;
        opacity: 1; }
      .pinheiro {
        transition: transform 3000ms ease-out 1000ms, opacity 3000ms ease-in 1000ms;
        transform:  translateY(0) scale(1);
        opacity: 1; } }
    .letering {
      transition: opacity 3500ms ease-out 2500ms;
      opacity: 1; } }

  .logo-header {
    position: absolute;
    top: 50%;
    left: 50%;
    max-width: 60%;
    transform: translate(-50%, -50%); } }
.slogan {
  background-color: $white;

  .wraper-slogan {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    background-color: $white;

    .views-field-body {
      p {
        font-size: 1.2em;
        text-align: center;
        font-weight: 300; } }
    .views-field-view-node {
      position: relative;
      text-align: right;
      font-size: 1em;
      font-weight: 300;
      text-transform: lowercase;
      a {
        padding-right: 0px;
        opacity: 1;
        transition: all 350ms ease 200ms;
        &:hover {
          padding-right: 40px;
          &::before {
            opacity: 1;
            transition: opacity 350ms ease 200ms; } }
        &::before {
            content: "";
            position: absolute;
            top: 2px;
            right: 0;
            width: 33px;
            height: 21px;
            opacity: 0;
            transition: opacity 500ms ease;
            background-image: url("../img/setas/setadireita.svg");
            background-position: 50% 50%;
            background-size: cover; } } } } }

// End Front Page --------------------------------------------------------------



// Thirdt Section -- Products
.thirdsection {
  position: relative;
  float: left;
  width: 100%;
  background-color: $white; }

.view-display-id-produtos {
  width: 100%;
  max-width: 90%;
  margin: 0 auto; }
#block-views-home-page-produtos {
  padding: 4em 0;
  display: block;
  background-color: white;
  h2 {
    font-size: 2em;
    font-weight: 200;
    max-width: 95%;
    color: $primary-color;
    text-transform: uppercase;
    text-align: center;
    margin: auto;
    padding: 1em 0; }

  .views-field-field-imagem {
    border-radius: 50%;
    width: 200px;
    height: 200px;
    overflow: hidden; }

  .views-field-view-node {
    text-align: right;
    font-size: 1em;
    font-weight: 300;
    text-transform: lowercase; }

  .view-content {
    position: relative;
    .views-row {
      float: left;
      width: 100%;
      padding: 4em 0;
      background: white;
      position: relative;

      .views-field-title {
        h3 {
          font-weight: 200;
          color: $primary-color; } }

      .views-field-body {
        font-weight: 300; }
      .views-field-field-imagem {
        display: inline-block;
        width: 150px;
        height: 150px;
        border-radius: 50%;
        overflow: hidden; }
      .views-field-title {
        h3 {
          font-size: 1.5em;
          font-weight: 300;
          color: $primary-color; } } }
    .views-row {
      &::before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 50%;
        height: 100%;
        width: 6px;
        background-color: $primary-color;
        margin-left: -3px; } }
    .views-row-odd {
      text-align: center;
      .align {
        padding: 3em 20px;
        position: relative;
        background: linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(255,255,255,0.98) 6%, rgba(255,255,255,0.98) 95%, rgba(255,255,255,0) 100%);


        .views-field-view-node {
          text-align: right;
          position: relative;
          display: inline-block;
          a {
            padding-right: 0px;
            opacity: 1;
            transition: all 350ms ease;
            &:hover {
              padding-right: 40px;
              &::before {
                opacity: 1;
                transition: opacity 350ms ease 200ms; } }
            &::before {
              content: "";
              position: absolute;
              top: 2px;
              right: 0;
              width: 33px;
              height: 21px;
              opacity: 0;
              background-image: url("../img/setas/setadireita.svg");
              background-position: 50% 50%;
              background-size: cover; } } } } }

    .views-row-even {
      text-align: center;
      .align {
        position: relative;
        padding: 3em 20px;
        background: linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(255,255,255,0.98) 6%, rgba(255,255,255,0.98) 95%, rgba(255,255,255,0) 100%);

        .views-field-view-node {
          text-align: left;
          position: relative;
          display: inline-block;
          a {
            padding-right: 0px;
            opacity: 1;
            transition: all 350ms ease;
            &:hover {
              padding-right: 40px;
              &::before {
                opacity: 1;
                transition: opacity 350ms ease 200ms; } }
            &::before {
              content: "";
              position: absolute;
              top: 2px;
              right: 0;
              width: 33px;
              height: 21px;
              opacity: 0;
              background-image: url("../img/setas/setadireita.svg");
              background-position: 50% 50%;
              background-size: cover; } } } } } }

  .view-content::after {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    width: 10px;
    height: 100%;
    background-color: $primary-color; } }

//-------------------Aplicações-------------------------------------------------
.fourthsection {
  position: relative;
  width: 100%;
  float: left;
  background-color: $primary-color; }

#block-views-home-page-aplicacoes {
  padding: 2em 0;
  background-color: $primary-color;
  h2 {
    font-size: 2em;
    font-weight: 200;
    color: white;
    text-transform: uppercase;
    text-align: center; }

  .round-image {
    margin: 0 auto;
    width: 190px;
    height: 190px;
    border-radius: 200px;
    overflow: hidden; }

  .views-field-title {
    margin-top: 2em;
    text-align: center;
    span {
      font-weight: 500;
      text-transform: uppercase;
      color: white; } }

  .views-field-body {
    text-align: center;
    p {
      padding: 0.5em 0;
      font-weight: 300; } }

  .views-field-view-node {
    text-align: center;
    a {
      font-weight: 300;
      text-transform: lowercase;
      color: white; } } }
//---------------------------Footer---------------------------------------------
footer {
  position: relative;
  float: left;
  display: block;
  width: 100%;
  z-index: 0;
  #map-canvas {
    position: relative;
    float: left;
    height: 500px;
    width: 100%;
    background-color: $white; }

  .footer {
    position: absolute;
    bottom: 0;
    height: 60px;
    padding: 10px 0;
    width: 100%;
    background-color: $primary-color;
    .logotipo {
      display: block;
      position: relative;
      height: 40px;
      background: url("../img/footer/terramotriz-branco.svg") no-repeat;
      background-size: contain; }

    .powered {
      height: 30px;
      margin: 5px 0;
      float: right;
      text-align: right;
      span {
        display: block;
        font-size: 0.6em;
        margin-right: 0.7em;
        color: white;
        text-transform: 300; }
      a {
        display: block;
        width: 133px;
        height: 12px;
        background: url("../img/footer/digitalimpulse.svg");
        background-repeat: no-repeat; } } } }

//--------------------------  News Page  ---------------------------------------
.node-noticia {
  .node-background {
    height: 400px;
    overflow: hidden;
    position: relative;
    background-position: bottom center;
    background-repeat: no-repeat;
    background-size: cover;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(to bottom, rgba(255,255,255,0.9) 0%,rgba(255,255,255,0) 70%); } }
  .description {
    padding-top: 5em;
    padding-bottom: 4em;
    h2 {
      font-size: 2em;
      font-weight: 200;
      text-align: center;
      color: $primary-color;
      text-transform: uppercase;
      margin-bottom: 1em; } }
  .field-name-body {
    font-size: 1em;
    text-align: center;
    font-weight: 300; } }


.view-display-id-noticias {
  .background-noticias {
    height: 400px;
    overflow: hidden;
    position: relative;
    background-position: bottom center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url("../img/noticias.jpg");
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(to bottom, rgba(255,255,255,0.9) 0%,rgba(255,255,255,0) 70%); } }
  h3 {
    max-width: 80%;
    margin: 0 auto;
    font-size: 2.2em;
    color: $primary-color;
    text-align: center;
    font-weight: 200;
    margin-top: 1em;
    text-transform: uppercase; }

  .notices-wrapper {
    width: 100%;
    max-width: 1336px;
    margin: 0 auto;
    padding-top: 2rem;
    padding-bottom: 5em;
    padding-left: 0.9375rem;
    padding-right: 0.9375rem;
    background-color: $white;
    .view-content {
      display: flex;
      justify-content: flex-start;
      flex-flow: row wrap; }
    .views-row {
      width: 280px;
      margin: 2em auto;
      .views-field-field-imagem {
        width: 150px;
        border-radius: 150px;
        margin: 1em auto;
        overflow: hidden; }
      .views-field-title {
        text-align: center;
        a {
          text-align: center;
          color: $primary-color;
          font-size: 1em;
          text-transform: uppercase;
          font-weight: 500; } } } } }
//--------------------------ABOU US PAGE ---------------------------------------
#node-2 {
  .node-background {
    height: 400px;
    overflow: hidden;
    position: relative;
    background-position: bottom center;
    background-repeat: no-repeat;
    background-size: cover;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(to bottom, rgba(255,255,255,0.9) 0%,rgba(255,255,255,0) 70%); } }

  .aboutsection-node {
    float: left;
    padding: 5em 0;
    background-color: $white;
    h2 {
      font-size: 2em;
      text-align: center;
      text-transform: uppercase;
      font-weight: 200;
      color: $primary-color;
      margin-bottom: 1em; }
    p {
      text-align: center;
      font-weight: 300;
      font-size: 1em; } }

  .secondsection-node {
    padding-bottom: 2.5em;
    padding-top: 2.5em;
    display: block;
    float: left;
    width: 100%;
    background-color: $primary-color;
    text-align: center;
    .body {
      display: table-cell;
      vertical-align: middle;
      color: $white;
      .field-name-field-titulo {
        font-weight: 200;
        color: $white;
        text-transform: uppercase;
        font-size: 2em; }
      p {
        font-weight: 300;
        font-size: 1em; } }
    .image {
      padding: 4em 0;
      img {
        display: table-cell;
        vertical-align: middle;
        margin: 0 auto; } } }


  .parteners {
    padding: 5em 0;
    float: left;
    width: 100%;
    background: $white;
    overflow: hidden;
    h2 {
      font-weight: 200;
      text-align: center;
      color: $primary-color;
      text-transform: uppercase; }
    .field-items {
      max-width: 950px;
      margin: 0 auto;
      display: flex;
      justify-content: flex-start;
      flex-flow: row wrap;
      .field-item {
        width: 200px;
        margin: 1em auto; } } } }

//--------------------------Products List Page------------------------------------
#block-views-home-page-produtos-titles {
  float: left;
  width: 100%; }
.node-produtos, .node-aplicacoes {
  background-color: $white;
  .node-background {
    width: 100%;
    height: 400px;
    position: relative;
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(to bottom, rgba(255,255,255,0.8) 0%,rgba(255,255,255,0) 50%); } }
  .description {
    padding-top: 5em;
    padding-bottom: 4em;
    h2 {
      font-size: 2em;
      font-weight: 200;
      text-align: center;
      color: $primary-color;
      text-transform: uppercase;
      margin-bottom: 1em; } }
  .field-name-body {
    font-size: 1em;
    text-align: center;
    font-weight: 300; }

  .view-display-id-produtos_titles, .view-display-id-aplicacoes_titles {
    width: 100%;
    max-width: 1336px;
    margin: 0 auto;
    padding-top: 2rem;
    padding-bottom: 5em;
    padding-left: 0.9375rem;
    padding-right: 0.9375rem;
    background-color: $white;
    .view-content {
      display: flex;
      justify-content: flex-start;
      flex-flow: row wrap; }
    .views-row {
      width: 280px;
      margin: 2em auto;
      .views-field-field-imagem {
        width: 150px;
        border-radius: 150px;
        margin: 1em auto;
        overflow: hidden; }
      .views-field-title {
        h3 {
          text-align: center;
          color: $primary-color;
          font-size: 1em;
          text-transform: uppercase;
          font-weight: 500; } } } } }


//------------------------Contact Node------------------------------------------
.page-node-1, .node-contacto {
  background-color: $white;
  .wrap-contact {
    display: block;
    overflow: hidden;
    padding-top: 5em;
    .image {
      max-width: 300px;
      display: block;
      margin: 2em auto; }
    .view-display-id-about {
      max-width: 100%;
      margin: 0 auto;
      text-align: center;
      padding-top: 3em;
      padding-bottom: 3em;
      font-weight: 300; } }
  .views-field-view-node {
    display: none; }
  .contact-information {
    padding-top: 2em;
    padding-bottom: 4em;
    background-color: $white;
    .info {
      text-align: center;
      color: $secondary-color;
      span {
        display: block; } }
    .social {
      width: 160px;
      margin: 0 auto;
      .mapa,.phone,.email {
        display: inline-block;
        width: 40px;
        height: 40px;
        margin: 5px;
        background-size: cover; }
      .mapa {
        background-image: url("../img/mapa-verde.svg"); }
      .email {
        background-image: url("../img/mail-verde.svg"); }
      .phone {
        background-image: url("../img/phone-verde.svg"); } } } }


//------------------------Product Node------------------------------------------

.node-produto, .node-aplicacao {
  background-color: $white;
  .node-background {
    width: 100%;
    height: 400px;
    position: relative;
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: $white;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(to bottom, rgba(255,255,255,0.9) 0%,rgba(255,255,255,0) 70%); } }
  .content {
    padding: 4em 0;
    overflow: hidden;
    text-align: center;
    .title {
      max-width: 80%;
      margin: 0 auto;
      font-size: 2.2em;
      color: $primary-color;
      text-align: center;
      font-weight: 200;
      margin-bottom: 1em;
      text-transform: uppercase; }
    p {
      font-size: 1.3em;
      padding: 0.5em 0;
      font-weight: 300; }
    .field-name-body {
      p {
        font-size: 1em;
        padding-bottom: 1em;
        font-weight: 300; } } }

  .services {
    width: 100%;
    padding: 2em 0;
    background-color: $primary-color;

    .slick {
      .slick-slide {
        outline: none; }
      .slick-prev {
        display: none !important;
        outline: none;
        position: absolute;
        left: 15px;
        height: 49px;
        width: 80px;
        top: 50%;
        margin-top: -25px;
        background-image: url(../img/setas/setaesquerda-branca.svg);
        background-repeat: no-repeat;
        background-size: cover;
        z-index: 99; }
      .slick-next {
        display: none !important;
        outline: none;
        position: absolute;
        right: 15px;
        height: 49px;
        width: 80px;
        top: 50%;
        margin-top: -25px;
        background-image: url(../img/setas/setadireita-branca.svg);
        background-repeat: no-repeat;
        background-size: cover;
        z-index: 99; }

      .field-name-field-titulo-destaque {
        margin: 0 auto;
        max-width: 75rem;
        font-size: 2em;
        text-align: center;
        color: $white;
        font-weight: 200;
        margin-bottom: 0.5em; }
      .field-name-field-body-destalhes {
        font-weight: 300;
        text-align: center; } } } }



//------------------------Web Form----------------------------------------------

.block-webform {
  float: left;
  position: relative;
  width: 100%;
  padding-top: 5em;
  padding-bottom: 5em;
  background: url("../img/contact.png") no-repeat;
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
  box-shadow: inset 0 0 0 1000px rgba($primary-color, 0.7);
  h2 {
    font-weight: 200;
    text-align: center;
    color: $white;
    font-size: 2.2rem;
    text-transform: uppercase;
    padding-bottom: 0.5em; }
  .webform-client-form {
    width: 100%;
    #edit-submitted-nome {
      background-position: 2% 50%;
      background-size: 15px;
      background-repeat: no-repeat;
      background-image: url("../img/formulario/nome.svg"); }
    #edit-submitted-e-mail {
      background-position: 2% 50%;
      background-size: 15px;
      background-repeat: no-repeat;
      background-image: url("../img/formulario/mail.svg"); }
    #edit-submitted-mensagem {
      background-position: 2% 12%;
      background-size: 15px;
      background-repeat: no-repeat;
      background-image: url("../img/formulario/menssagem.svg"); }
    input, textarea {
      position: relative;
      font-weight: 300;
      color: $white;
      padding: 3px 0 3px 35px;
      border: 2px solid $white;
      background-color: transparent;
      box-shadow: none;
      resize: none; }
    input:focus::placeholder {
      transition: opacity 0.3s 0.3s ease;
      opacity: 0; }
    textarea:focus::placeholder {
      transition: opacity 0.3s 0.3s ease;
      opacity: 0; }
    ::placeholder {
      color: $white;
      text-transform: lowercase; }
    .grippie {
      display: none; }
    .webform-submit {
      width: 150px;
      padding: 15px;
      text-transform: lowercase;
      color: $primary-color;
      font-weight: 500;
      background-color: $white;
      border: 2px solid $white; } }

  .form-actions {
    text-align: right; } }

//------------------------Info Bubble-------------------------------------------

.transparent {
  position: absolute;
  top: 25px; }

#info-bubble {
  width: 100%;
  height: 100%;
  text-align: center;
  overflow: hidden;
  position: relative;
  .social {
    width: 120px;
    height: 50px;
    padding: 10px 0;
    margin: 0 auto;
    .email,.phone,.mapa {
      float: left;
      width: 30px;
      height: 30px;
      margin: 0 5px;
      background-repeat: no-repeat; }
    .mapa {
      background-image: url("../img/footer/mapa.svg"); }
    .phone {
      background-image: url("../img/footer/telemovel.svg"); }
    .email {
      background-image: url("../img/footer/mail.svg"); } }
  a,.email,.street,.phone {
    float: left;
    width: 100%;
    font-weight: 500;
    font-size: 1.2em;
    color: $primary-color; } }




.l-messages {
  position: absolute;
  top: 0%;
  left: 50%;
  max-width: 500px;
  background-color: $primary-color;
  color: $white;
  border-radius: 25px; }

#block-logintoboggan-logintoboggan-logged-in {
  display: none; }
// User-login
.page-user {
  footer {
    display: none; } }
.logged-in {
  .user-login {
    height: calc(100vh - 29px); } }



.user-login {
  height: 100vh;
  background-color: $white;
  position: relative;
  background-image: linear-gradient(to bottom, rgba(255,255,255,0.8) 0%,rgba(255,255,255,0) 100%), url('../img/contact.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  .page-title-container {
    padding-top: 15%; }

		.background-shape {
			padding: 1em 1em 1em 1em;
			background-color: rgba(0,0,0,0.5);
			overflow-y: auto;

			.page-title {
        max-width: 600px;
        margin: 0.25em auto 0.25em;
        padding-bottom: 1em;
        text-align: center;
        font-size: 30px;
        font-weight: 300;
        color: white;
        text-transform: uppercase; }

			.profile {
				color: white;
				text-align: center;

				h3 {
					color: white; }

				a {
					color: white;
					opacity: 0.7 {
          transition: opacity 300ms linear; } }

				a:hover {
					opacity: 1;
					transition: opacity 300ms linear; } }

			.toboggan-unified {
				#login-message {
					display: none; }

				#login-links {
					display: none; }

				#login-form {
					max-width: 450px;
					margin: auto;

					form {
						margin: 0px;
						text-align: right;

						.form-item {
							margin-bottom: 0em;
							text-align: left;

							label {
								display: none; }

							input {
                height: 60px;
                margin-bottom: 7px;
                border: none;
                background-color: rgba(0,0,0,0.6);
                color: white;
                font-weight: 300;
                font-size: 1.5em; }

							[placeholder]:focus::-webkit-input-placeholder {
								transition: opacity 0.5s 0.5s ease;
								opacity: 0; }

							[placeholder]:focus::-moz-input-placeholder {
								transition: opacity 0.5s 0.5s ease;
								opacity: 0; }

							[placeholder]:focus::-ms-input-placeholder {
								transition: opacity 0.5s 0.5s ease;
								opacity: 0; }

							input:focus {
								box-shadow: none; } }

						.form-type-textfield {
							margin-bottom: 3em; }

						.description {
              display: none; }

						.login-forgot {
							display: block;
							margin-bottom: 2em;

							a {
								font-size: 14px;
								color: white;
								font-weight: 300;
								border-bottom: 1px dotted $primary-color; } } } } }

			#user-pass {
				text-align: right;

				label {
					display: none; }

				input {
					height: 60px;
					margin-bottom: 7px;
					border: none;
					background-color: rgba(0,0,0,0.7);
					color: white;
					font-size: 2em; }

				[placeholder]:focus::-webkit-input-placeholder {
					transition: opacity 0.5s 0.5s ease;
					opacity: 0; }

				[placeholder]:focus::-moz-input-placeholder {
					transition: opacity 0.5s 0.5s ease;
					opacity: 0; }

				[placeholder]:focus::-ms-input-placeholder {
					transition: opacity 0.5s 0.5s ease;
					opacity: 0; }

				input:focus {
					box-shadow: none; } } } }

//Maintenance page
#page-manutencao {
  position: relative;
  float: left;
  height: 100%;
  width: 100%;
  background-color: $white;
  .center-vertical {
    display: block;
    position: absolute;
    width: 100%;
    top: 50%;
    left: 0px;
    text-align: center;
    transform: translateY(-50%);

    h1, h3 {
      color: $black; }
    a {
      text-decoration: none; } } }
