.slick {
  padding: 3em 0em;
  .slide {
    width: 320px;
    padding: 0 0; } }

.flex-direction-nav, .slick__arrow {
  a.flex-next, a.flex-prev, i.slick-next, i.slick-prev {
    display: none !important; } }


// Front Page Start ------------------------------------------------------------
.start {
	height: 500px;
	background-color: $white;
	.logo-animation-start {
		position: absolute;
		width: 350px;
		max-width: 930px;
		height: 400px;
		margin-left: -175px;
		margin-top: -200px;

		.logo {
			.pinheiro {
				width: 400px;
				height: 100%; } } } }

.slogan {
	.wraper-slogan {
		.views-field-body {
			p {
				font-size: 1.4em; } } } }

// End Front Page --------------------------------------------------------------

#node-2 {
  .node-background {
    height: 60vh; } }

//Topbar and menu
#menu-wrapper {
	.menu-links {
		#block-system-main-menu {
			.menu {
				li {
					a {
						font-size: 3.5em; } } } } } }
//--------------------------Products List Page----------------------------------
.node-produtos, .node-aplicacoes {
  .node-background {
    height: 60vh; } }


//--------------------------Pagina Noticias-------------------------------------
.view-display-id-noticias {
  .background-noticias {
    height: 60vh; }
  h3 {
    font-size: 3.2em; } }

//--------------------------Produto e Aplcação----------------------------------
.node-produto,.node-aplicacao {
  .node-background {
    height: 60vh; } }

h2 {
	font-size: 2.2em !important; }


//------------------------Produtos----------------------------------------------
#block-views-home-page-produtos {
  h2 {
    font-size: 3em;
    font-weight: 200;
    max-width: 500px;
    color: $primary-color;
    text-transform: uppercase;
    text-align: center;
    margin: auto;
    padding: 1em 0; } }


// Footer
footer {
  #map-canvas {
    max-height: 70vh; } }

//------------------------Contact Node------------------------------------------
.page-node-1, .node-contacto {
  .wrap-contact {
    .image {
      max-width: 90%; }
    .view-display-id-about {
      max-width: 50%; } } }
