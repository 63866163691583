.gm-style-iw {
	width: 250px !important;
	min-height: 150px !important; }

//Ajax throbber
.ajax-progress {
	display: none; }

//Topbar and menu
.js-document-closed {
	transform: translateX(0px);
	transition: transform 700ms cubic-bezier(0.91, 0.01, 0.6, 0.99); }

.js-document-opened {
	transform: translateX(100%);
	transition: transform 700ms cubic-bezier(0.91, 0.01, 0.6, 0.99); }

.menu-wrapper-open {
	z-index: 1;
	transition: z-index 200ms linear 700ms;
	.menu-links {
		transform: translateX(0%);
		transition: transform 700ms cubic-bezier(0.82, 0.01, 0.77, 0.78); } }

.hamburger.hamburger-open {
	background-color: transparent;
	transition: background-color 300ms linear; }

.menu-wrapper-closed {
	.menu-links {
		transform: translateX(-50%);
		transition: transform 700ms cubic-bezier(0.82, 0.01, 0.77, 0.78); } }

.hamburger.hamburger-closed {
	transition: background-color 300ms linear, transform 400ms ease-in-out; }

#menu-wrapper {
	position: fixed;
	width: 100%;
	padding: 90px 20px 20px 15px;
	top: 0px;
	overflow: hidden;
	background-color: $white;
	bottom: 0px;
	.menu-links {
		position: relative;
		width: 100%;
		padding: 0px;

		#block-system-main-menu {
			.menu {
				position: relative;
				width: 100%;
				margin: 0px;
				padding: 0px;
				z-index: 99;
				text-align: left;
				li {
					width: 100%;
					display: block;
					position: relative;
					padding: 2px 0px 2px;

					a {
						font-size: 2.5em;
						text-decoration: none;
						text-transform: uppercase;
						color: $primary-color;
						font-weight: 700;
						letter-spacing: 0px;
						line-height: 1;
						transform: translateX(0px);
						transition: color 300ms linear; }

					a:hover {
						color: darken($primary-color, 25);
						transition: color 300ms ease; } } } } } }

.menu-wrapper {
  transform: translateX(0px);

  .menu-links {
    .menu {
      li {
        opacity: 1;
        transform: translateX(0px); } } } }

#topbar {
  position: absolute;
  width: 100%;
  height: 70px;
  z-index: 4;
  top: 0px;
  .hamburger {
    display: block;
    position: absolute;
    padding: 15px;
    top: 15px;
    left: 23px;
    z-index: 99999;

    #nav-icon {
      width: 30px;
      height: 21px;
      position: relative;
      margin-right: 0px;
      margin-left: auto;
      transform: rotate(0deg);
      transition: transform .5s ease-in-out;
      cursor: pointer;
      .text {
        position: absolute;
        left: 40px;
        top: -13px;
        font-size: 30px;
        color: $primary-color;
        text-transform: uppercase;
        user-select: none;
        opacity: 1;
        pointer-events: auto;
        transition: opacity 500ms ease 600ms; } }

    #nav-icon span {
      display: block;
      position: absolute;
      height: 3px;
      width: 100%;
      background-color: $primary-color;
      border-radius: 0px;
      opacity: 1;
      left: 0; }

    #nav-icon span:nth-child(1) {
      top: 0px;
      border-radius: 20px;
      transform-origin: 50%, 50%;
      transition: top 150ms linear 250ms, transform 250ms ease 0ms, background-color 350ms ease 600ms; }

    #nav-icon span:nth-child(2) {
      top: 9px;
      width: 100%;
      margin: 0px 0% 0px;
      border-radius: 20px;
      transition: background-color 350ms ease 600ms; }

    #nav-icon span:nth-child(3) {
      top: 18px;
      transform-origin: 50%, 50%;
      border-radius: 20px;
      transition: top 150ms linear 250ms, transform 250ms ease 0ms, background-color 350ms ease 600ms; }

    #nav-icon.open {
      .text {
        opacity: 0;
        pointer-events: none;
        transition: opacity 500ms ease; } }

    #nav-icon.open span {
      background-color: $primary-color; }

    #nav-icon.open span:nth-child(1) {
      transform: rotate(45deg);
      top: 10px;
      transition: top 150ms linear, transform 250ms ease 150ms, background-color 250ms ease 500ms; }

    #nav-icon.open span:nth-child(2) {
      width: 0%;
      margin: 0px 50% 0px;
      transition: width 250ms ease-in-out, margin 250ms ease-in-out, background-color 250ms ease 500ms; }

    #nav-icon.open span:nth-child(3) {
      transform: rotate(-45deg);
      top: 10px;
      transition: top 150ms linear, transform 250ms ease 150ms, background-color 250ms ease 500ms; } } }

// End TopBar
.page-user {
	input[type=submit] {
	  outline: none;
	  margin: 0px;
	  border: 2px solid $primary-color;
	  box-shadow: none;
	  background-color: $primary-color !important;
	  color: $white;
	  line-height: 1;
	  min-width: 100px;
	  font-size: 1em !important;
	  height: 40px !important;
	  padding: 0.5em 1em 0.5em 1em;
	  border-radius: 0px;
	  box-shadow: none;
	  opacity: 1;
	  text-transform: uppercase;
	  text-align: center; } }

//Flexslider "corrections"
.flexslider {
	margin: 0px;
	border: 0px none;
	border-radius: 0px;
	box-shadow: 0 0 0 transparent; }

.flex-direction-nav, .slick__arrow {
  a.flex-next, a.flex-prev, i.slick-next, i.slick-prev {
    display: block !important;
    position: absolute;
    margin-top: -20px;
    width: 120px;
    height: 74px;
    transform: scale(0.75);
    transition: transform 500ms ease, opacity 500ms ease;

    &::before {
      content: "";
      display: inline-block;
      background-repeat: no-repeat;
      background-position: 100% 50%;
      width: 120px;
      height: 74px; }

    &:hover {
      transform: scale(0.9);
      transition: transform 250ms ease, opacity 500ms ease; } }

  a.flex-next, i.slick-next {
    right: 20px !important;

    &::before {
      background-image: url(../img/setas/setadireita-branca.svg); } }

  a.flex-prev, i.slick-prev {
    left: 20px !important;

    &::before {
  	  background-image: url(../img/setas/setaesquerda-.svg); } } }

.flex-control-paging, .slick-dots {
	position: absolute;
	bottom: 15px;
	width: 100%;
	z-index: 99;

	li {
		display: inline-block;
		vertical-align: middle;
		position: relative;
		margin: 0 2px;
		background: transparent;
		position: relative;
		float: none;

		a {
			background: white;
			width: 10px;
			height: 10px;
			border-radius: 50%;
			transition: background 200ms ease; }

		a:hover {
			background: darken($primary-color, 25);
			transition: background 200ms ease; }

		a.flex-active {
			background: $primary-color;
			transition: background 200ms ease; }

		a.flex-active:hover {
			background: darken($primary-color, 25);
			transition: background 200ms ease; } } }

//Slick slider
.slick {
  position: relative;
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;

  .slick-track {
    margin: auto; }

  .slide {
    width: 320px;
    margin: 0 2em;
    text-align: center;
    &:focus {
      outline: none; }

    img {
      display: inline-block; } }

  .slick-dots {
    display: none !important; }

  .slick__arrow {
    i.slick-next, i.slick-prev {
      top: 45%; } } }

.flex-direction-nav, .slick__arrow {
  a.flex-next, i.slick-next {
    &::before {
      background-image: url(../img/setas/setadireita-branca.svg); } }

  a.flex-prev, i.slick-prev {
    &::before {
  	  background-image: url(../img/setas/setaesquerda-branca.svg); } } }


.flex-control-paging {
	li {
		a:hover {
			background: darken($white, 25); }

		a.flex-active {
			background: $white; }

		a.flex-active:hover {
			background: darken($white, 25); } } }
